// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
//@import "~@coreui/coreui-pro/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

:root {
  --font-family-sans-serif: "Avenir Next LT Pro", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol,  sans-serif";
}
